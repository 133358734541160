import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { firestore, auth, signInWithGoogle } from '../../firebase'; // Import auth to get the current user
import { MainDiv } from '../PostDeals/styledComponents';
import emailjs from 'emailjs-com';


const PostDeal = () => {
  const [dealData, setDealData] = useState({
    ebayUrl: '',
    description: ''
  });
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const form = useRef();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setDisplayName(user.displayName);
        setIsLoggedIn(true);
      } else {
        setDisplayName('anonymous');
        setIsLoggedIn(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDealData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    signInWithGoogle()
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
      });
  };

  const handleCheckboxChange = (e) => {
    setIsAnonymous(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser; // Get the current user
    const additionalData = {
      submittedDate: new Date().toISOString(),
      submittedBy: isAnonymous ? 'anonymous' : (user ? user.displayName : 'anonymous'),
      IsApproved: '', // Set the initial value of IsApproved to false
      SellerPlatform: 'eBay'
    };
    try {
      const docRef = await addDoc(collection(firestore, 'PostDeals'), {
        ...dealData,
        ...additionalData
      });
      alert('Deal submitted successfully!');
      setDealData({ ebayUrl: '', description: '' });
      setIsAnonymous(false);

      // Send email using EmailJS
      emailjs.sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
          
        });
    } catch (error) {
      console.error('Error submitting deal: ', error);
    }
  };

  return (
    <MainDiv>
      <div className="container">
        <div className="mt-5 w-75">
        <h1>Post A Deal </h1>

          <div className="mt-3">
            <p><b>Please Note:</b> <span className="text-danger"><u>At this time, we are exclusively accepting “Buy It Now” watch deals from eBay only. 
            We are actively working to expand our platform to include additional vendors. </u></span> 
            We appreciate your patience and understanding during this process. Please allow 
            up to 72 hours for our moderators to approve and display the deal on the site.</p>
          </div>

          <form ref={form} onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="ebayUrl" className="form-label"><b>eBay Deal URL:</b></label>
              <input
                type="url"
                className="form-control"
                id="ebayUrl"
                name="ebayUrl"
                value={dealData.ebayUrl}
                onChange={handleChange}
                placeholder="Enter eBay Deal URL"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label"><b>Description:</b></label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={dealData.description}
                onChange={handleChange}
                placeholder="Enter Deal Description"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="displayName" className="form-label"><b>Posted by:  &nbsp;</b></label>
              <span>
                {isAnonymous ? (
                  <>
                    anonymous 
                  </>
                ) : (
                  <>
                  {displayName} &nbsp;&nbsp;
                  <span>{isLoggedIn ? '' : <a href="#" onClick={handleLogin}>Log in to display name.</a>}</span>
                  </>
                )}
              </span>
            </div>
            <div className="mb-3 form-check">
              {isLoggedIn ?               
              <>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="anonymousCheck"
                  checked={isAnonymous}
                  onChange={handleCheckboxChange}
                /><label className="form-check-label" htmlFor="anonymousCheck"> Make it anonymous</label> 
              </> : ''}
            </div>
            <button type="submit" className="btn btn-primary">Submit Deal</button>
          </form>
          <div className="mt-3">
            <p className="text-muted">
              By submitting this form, you agree to our <a href="/termsandconditions" target="_blank">terms of service</a>. Please ensure that the information provided is accurate and complies with our guidelines.
            </p>
          </div>
          
          
        </div>
      </div>
    </MainDiv>
  );
};

export default PostDeal;